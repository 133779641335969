<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
  async mounted() {
    // let ls = localStorage.getItem("email");
    // if (ls && this.$route.name != "CopyWriting") this.$router.push({ name: "CopyWriting" });
    // else if (!ls && this.$route.name != "AccessByEmail") this.$router.push({ name: "AccessByEmail" });

    // login code
    let token = localStorage.getItem("token");
    if (token && this.$route.name != "CopyWriting") return this.$router.push({ name: "CopyWriting" });
    // else if (token && this.$route.name != "BlogWriting" && ["blogger"].includes(this.$store.getters.getUser.type))
    //   return this.$router.push({ name: "BlogWriting" });
    else if (!token && this.$route.name != "Login") return this.$router.push({ name: "Login" });
  },
};
</script>

<style></style>
