<template>
  <v-form ref="form" v-model="valid" lazy-validation class="d-flex justify-center" style="flex-direction: column;">
    <h4 class=" text-center text-uppercase black--text py-5">Login</h4>
    <v-text-field @keyup.enter="validate()" v-model="email" :rules="emailRules" label="Email" required solo dense></v-text-field>
    <v-text-field @keyup.enter="validate()" v-model="password" :rules="passwordRules" label="Password" required type="password" solo dense></v-text-field>

    <v-btn style="background-color:#001536; color:#fcfcfc;" class="my-4" @click="validate()" :disabled="!valid || requestSent">
      Login
    </v-btn>
    <div class="small-font">{{ status }}</div>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    email: "",
    password: "",
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "Invalid E-mail"],
    passwordRules: [(v) => !!v || "Password is required", (v) => (v && v.length >= 8) || "Invalid Password"],

    valid: true,
    requestSent: false,
    status: "",
  }),

  methods: {
    async validate() {
      try {
        this.status = "Logging in...";
        let validated = this.$refs.form.validate();
        if (!validated) return (this.status = "Invalid Email or Password");

        // sending request to api
        this.requestSent = true;
        let res = await this.axios.post("/api/users/login", {
          email: this.email.toLowerCase(),
          password: this.password,
        });
        this.requestSent = false;
        this.status = res.data.message;
        if (!res.data.success) return;

        localStorage.setItem("token", res.data.token);
        this.axios.defaults.headers.common["Authorization"] = res.data.token;
        this.$router.push({ name: "Dashboard" });

        // if (!res.data.success && res.data.redirect === "unverifiedUser") {
        //   this.$router.push({ name: "PendingVerification", params: { email: this.email } });
        // } else if (!res.data.success && res.data.redirect === "unverifiedAdminUser") {
        //   this.$router.push({ name: "PendingAdminVerification" });
        // }
      } catch (error) {
        console.log(error);
        this.requestSent = false;
        this.status = "Could not log in - kindly check your internet connection";
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
::v-deep .small-font,
::v-deep .v-label,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
