<template>
  <!-- Data table for contct list: start -->

  <v-row class="justify-center my-3 mx-0">
    <v-col cols="12">
      <v-card>
        <template>
          <v-overlay :value="loading" z-index="1000">
            <v-snackbar v-model="loading" timeout="-1" centered>
              <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
              <span class="py-2 px-2">Processing...</span>
            </v-snackbar>
          </v-overlay>
          <!-- Snackbar for small info alerts -->
          <v-snackbar
            :value="showSnackbar"
            timeout="-1"
            :vertical="true"
            app
            bottom
            right
            style="opacity:0.9;z-index:2005"
            content-class="main-snackbar-content d-flex flex-column align-start justify-start"
          >
            <div class="py-0" style="font-size: 15px;">
              <b>{{ snackbarText }}</b>
            </div>
            <div class="py-1 text-capitalize " style="font-size: 13px;">
              <template v-if="typeof snackbarText2 == 'string'">
                <span>{{ snackbarText2 }}</span>
              </template>
              <template v-else>
                <template v-for="(value, key) in snackbarText2">
                  <div v-if="!['errors', 'failedDetails', 'success_ids'].includes(key)" :key="key">{{ key | removeUnderscore }}: {{ value }}</div>
                </template>
              </template>
            </div>
            <template v-slot:action="">
              <v-btn small color="blue" text @click="showSnackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>

          <v-row class="d-flex justify-space-between pa-5">
            <div style="width: 400px;">
              <v-text-field
                @keyup.enter="search_notifications()"
                class="small-font"
                v-model="search"
                label="Type keyword & Press Enter to search"
                hide-details
                dense
                solo
              ></v-text-field>
            </div>
            <div>
              <!-- refresh button -->
              <v-btn dark outlined @click="initialize()">
                <v-icon color="blue">mdi-refresh</v-icon>
              </v-btn>

              <v-menu offset-y bottom offset-x left dense>
                <!-- Options Menu Button -->
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    :value="selected.length"
                    :content="selected.length"
                    class="notification_badge ml-8 mr-8"
                    bordered
                    bottom
                    overlap
                    color="blue accent-1"
                    offset-x="10"
                    offset-y="10"
                  >
                    <v-icon class="" color="blue" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </v-badge>
                </template>

                <v-list class="rounded-0" dense>
                  <v-list-item v-for="link in bulk_options" :key="link.id" @click="link.action(selected, true)" :disabled="!selected.length">
                    <!-- <v-list-item-icon>
                      <v-icon :color="link.color">{{ link.icon }}</v-icon>
                    </v-list-item-icon> -->

                    <v-list-item-content>
                      <v-list-item-title class="item-actions-menu">
                        <v-icon small :color="link.color">{{ link.icon }}</v-icon>
                        {{ link.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>
          <!-- users datatable -->
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="notifications"
            :options.sync="options"
            :server-items-length="notifications_count"
            show-select
            :single-select="singleSelect"
            sort-by="name"
            class="py-5 rounded-0"
            dense
            :footer-props="{ 'items-per-page-options': [10, 20, 30, 50, 100, 250] }"
          >
            <template v-slot:[`item.text`]="{ item }">
              <span>
                <a v-if="item.link.includes('http')" :href="item.link">{{ item.text }}</a>
                <router-link v-else :to="{ name: item.link, params: { data: item.data } }">{{ item.text }}</router-link>
              </span>
            </template>

            <template v-slot:[`item.module`]="{ item }">
              <span class="text-capitalize">{{ item.module | removeUnderscore }}</span>
            </template>

            <!-- User Admin Verified slot for text manipulation -->
            <template v-slot:[`item.read`]="{ item }">
              <v-icon small :color="item.read ? 'green' : 'blue'">{{ item.read ? "mdi-eye-check" : "mdi-eye-minus" }}</v-icon>
            </template>

            <!-- User role slot for text capitalization -->
            <template v-slot:[`item.createdAt`]="{ item }">
              <span cmall class="">{{ item.createdAt | formatDate }}</span>
            </template>

            <!-- Actions slot -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom offset-x left dense>
                <!-- Options Menu Button -->
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="" color="blue" v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
                </template>
                <!-- Option Menu List -->
                <v-list class="rounded-0" dense>
                  <v-list-item v-for="link in bulk_options" :key="item.id + link.id" @click="link.action([item], false)">
                    <v-list-item-content>
                      <v-list-item-title class="item-actions-menu">
                        <v-icon small :color="link.color">{{ link.icon }}</v-icon>
                        {{ link.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <!-- Empty table slot -->
            <template v-slot:no-data>
              <div>
                No Notifications to show...
              </div>
            </template>
          </v-data-table>
        </template>
        <!-- Data table for contct list: end -->
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  props: ["users", "usersRequest"],
  data() {
    const self = this;
    return {
      notifications: [],
      selected: [],
      headers: [
        { text: "Category", value: "title" },
        // { text: "Module", value: "module" },
        { text: "Notification", value: "text" },
        { text: "Time", value: "createdAt" },
        { text: "Read", value: "read" },
        { text: "Actions", value: "actions" },
      ],
      search: "",
      singleSelect: false,
      options: {
        page: 1,
        itemsPerPage: 20,
        itemsLength: 0,
        sortBy: ["id"],
        sortDesc: [true],
      },
      notifications_count: 0,

      // alerts fields
      requestSent: false,
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",

      bulk_options: [
        { id: 1, icon: "mdi-eye-check", text: "Mark as read", color: "green", action: self.markAsRead },
        { id: 2, icon: "mdi-eye-minus", text: "Mark as unread", color: "blue", action: self.markAsUnRead },
        { id: 3, icon: "mdi-trash-can", text: "Delete", color: "red", action: self.deleteNotifications },
      ],
    };
  },
  watch: {
    // new fields
    options() {
      this.getNotifications();
    },
  },

  computed: {
    loading() {
      return this.requestSent;
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.options.page = 1;
      this.search = "";
      await this.getNotifications();
    },
    async getNotifications() {
      try {
        this.requestSent = true;
        let res = await this.axios.post("/api/notifications/usernotifications", { options: this.options });
        this.requestSent = false;
        if (!res.data.success) return;
        this.notifications = res.data.notifications;
        this.notifications_count = res.data.count;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
      }
    },
    async search_notifications() {
      if (!this.search) return;
      let search = this.search;
      const options = this.options;
      try {
        this.requestSent = true;
        let res = await this.axios.post("/api/notifications/search", { search, options });
        this.requestSent = false;
        if (!res.data.success) return;
        this.notifications = res.data.notifications;
        this.notifications_count = res.data.count;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Could not search - Connection Error");
      }
    },
    async markAsRead(items, multiple) {
      try {
        let ids = items.map((i) => i.id);
        // setting values before update
        this.notifications.forEach((n) => {
          if (ids.includes(n.id)) n.read = 1;
        });
        let res = await this.axios.post("/api/notifications/markasread", { ids });
        if (!res.data.success) return;
        // setting selected after update
        if (multiple) this.selected = [];
      } catch (error) {
        console.log(error);
        this.setSnackbar(true, "Error", "Notification status could not be updated - Check your internet connection");
      }
    },
    async markAsUnRead(items, multiple) {
      try {
        let ids = items.map((i) => i.id);
        this.notifications.forEach((n) => {
          if (ids.includes(n.id)) n.read = 0;
        });

        let res = await this.axios.post("/api/notifications/markasunread", { ids });
        if (!res.data.success) return;
        // setting values after update
        if (multiple) this.selected = [];
      } catch (error) {
        console.log(error);
        this.setSnackbar(true, "Error", "Notification status could not be updated - Check your internet connection");
      }
    },
    async deleteNotifications(items, multiple) {
      try {
        let ids = items.map((i) => i.id);
        this.notifications = this.notifications.filter((n) => !ids.includes(n.id));
        let res = await this.axios.post("/api/notifications/deletenotifications", { ids });
        if (!res.data.success) return;
        if (multiple) this.selected = [];
      } catch (error) {
        console.log(error);
        this.setSnackbar(true, "Error", "Notification could not be deleted - Check your internet connection");
      }
    },
    setSnackbar(show, title, text) {
      this.showSnackbar = show;
      this.snackbarText = title;
      this.snackbarText2 = text;
    },
  },
  filters: {
    formatDate: function(date) {
      return moment(date).fromNow();
    },
    removeUnderscore(str) {
      return str.replace(/_/g, " ");
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
td a {
  text-decoration: none;
}
::v-deep .v-list-item__title {
  text-transform: capitalize;
}
::v-deep .v-label,
::v-deep .small-font,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .item-actions-menu {
  display: flex;
  justify-content: flex-start;
}
::v-deep .item-actions-menu i {
  margin-right: 8px;
}
</style>
