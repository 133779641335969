import Vue from "vue";
import Vuex from "vuex";

// importing modules
const { users } = require("./users");
const { appbar } = require("./appbar");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    appbar,
  },
});
