<template>
  <v-row>
    <v-col cols="12">
      <router-view></router-view>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "UsersMain",
};
</script>
<style scoped></style>
