<template>
  <v-layout row class="justify-center my-10 mx-5">
    <v-flex md12 class="d-flex justify-center">
      <v-card max-width="500px" width="500px" class="pa-5 rounded-0 ">
        <v-form ref="form" v-model="valid">
          <v-text-field v-model="passwords.currentPassword" type="password" :rules="currentPasswordRules" label="Current Password" required dense solo clearable class="rounded-0"></v-text-field>
          <v-text-field v-model="passwords.newPassword" type="password" :rules="newPasswordRules" label="New Password" required dense solo clearable class="rounded-0"></v-text-field>
          <v-text-field v-model="passwords.repeatNewPassword" type="password" :rules="repeatNewPasswordRules" label="Repeat New Password" dense solo clearable class="rounded-0"></v-text-field>

          <v-flex class="d-flex justify-end">
            <v-btn color="grey" outlined text class="ml-4 rounded-0 small-font">
              Cancel
            </v-btn>
            <v-btn :disabled="!valid || requestSent" color="red" outlined text class="ml-4 rounded-0 small-font" @click="validate" align-end>
              Save
            </v-btn>
          </v-flex>

          <v-flex md12 class="green--text d-flex justify-end mt-2 small-font" >{{ status }}</v-flex>
        </v-form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Reset-Password",
  data: () => ({
    valid: false,
    requestSent: false,
    status: "",
    passwords: { currentPassword: "", newPassword: "", repeatNewPassword: "" },
    currentPasswordRules: [(v) => !!v || "Current Password is required"],
    newPasswordRules: [(v) => !!v || "New Password is required", (v) => (v && v.length >= 8) || "Password should be greater than 8 characters"],
    repeatNewPasswordRules: [(v) => !!v || "Type the password again", (v) => (v && v.length >= 8) || "Password should be greater than 8 characters"],
  }),

  methods: {
    validate() {
      let validated = this.$refs.form.validate();

      if (!validated) {
        this.status = "Kindly check all the fields";
        return;
      }

      if (this.passwords.newPassword !== this.passwords.repeatNewPassword) {
        this.status = "Passwords do not match";
        return;
      }

      this.requestSent = true;
      this.status = "";

      this.axios
        .put("/api/users/user/changeselfpassword", this.passwords)
        .then((res) => {
          this.requestSent = false;
          this.status = res.data.message;
        })
        .catch((err) => {
          console.log(err);
          this.requestSent = false;
          this.status = "There was an error in updating the user, kindly check the connection.";
        });
    },
  },
};
</script>
<style scoped>
* {
  letter-spacing: normal;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
