<template>
  <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent app expand-on-hover>
    <v-list-item>
      <v-icon @click.stop="mini = !mini">mdi-menu</v-icon>
    </v-list-item>

    <v-divider></v-divider>
    <v-list dense shaped>
      <template v-for="(item, index) in links">
        <template v-if="item.items.length">
          <v-list-group :key="item.title" v-model="item.active" :prepend-icon="item.action" v-if="item.permission.includes($store.getters.getUser.group)">
            <!-- <v-list-group :key="item.title" v-model="item.active" :prepend-icon="item.action"> -->
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="small-font">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-slot:prependIcon>
              <v-icon color="#001536">{{ item.icon }}</v-icon>
            </template>

            <template v-for="(child, i) in item.items">
              <v-list-item :key="child.title + i" v-if="child.permissions.includes($store.getters.getUser.type)" :to="child.to">
                <v-list-item-icon>
                  <v-icon color="#001536">{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="small-font">{{ child.title }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
          <v-divider v-if="item.sectionEnd" :key="index + 100" class="my-1"></v-divider>
        </template>
        <template v-else>
          <!-- <v-list-group :key="item.title" v-model="item.active" :prepend-icon="item.action" v-if="item.permission.includes($store.getters.getUser.group)"> -->

          <v-list-item v-if="item.permission.includes($store.getters.getUser.type)" :to="item.to" :key="index">
            <!-- <v-list-item :to="item.to" :key="index"> -->
            <v-list-item-icon>
              <v-icon color="#001536">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="small-font">{{ item.title }}</v-list-item-title>
          <v-divider v-if="item.sectionEnd" :key="index + 100" class="my-1"></v-divider>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      mini: true,
      links: [
        {
          title: "Listing Copy",
          icon: "mdi-page-next-outline",
          permission: ["realestate_agent", "admin"],
          to: { name: "CopyWriting" },
          items: [],
          sectionEnd: true,
        },
        {
          title: "Generate Blog",
          icon: "mdi-post-outline",
          permission: ["blogger", "admin"],
          to: { name: "BlogWriting" },
          items: [],
          sectionEnd: true,
        },
        {
          title: "Tender",
          icon: "mdi-file-document-arrow-right",
          permission: ["tender_agent", "admin"],
          to: { name: "Tender" },
          items: [],
          sectionEnd: false,
        },
        {
          title: "Chat Demo",
          icon: "mdi-forum",
          permission: ["admin"],
          to: { name: "ChatDemo" },
          items: [],
          sectionEnd: false,
        },
        // {
        //   title: "Boards",
        //   icon: "mdi-briefcase",
        //   permission: ["user", "admin"],
        //   items: [
        //     { title: "Live Listings", to: { name: "Boards" }, icon: "mdi-briefcase-check-outline", permissions: ["admin", "std", "pre", "f50"] },
        //     // { title: "Home", to: { name: "Home" }, icon: "mdi-home ", permissions: ["admin", "std", "pre", "f50"] },
        //     // { title: "Live Listings", to: { name: "BStd" }, icon: "mdi-briefcase-check-outline", permissions: ["admin", "std"] },
        //     // { title: "Live Listings", to: { name: "BPre" }, icon: "mdi-briefcase-check-outline", permissions: ["admin", "pre"] },
        //     // { title: "Live Listings", to: { name: "BF50" }, icon: "mdi-briefcase-check-outline", permissions: ["admin", "f50"] },
        //   ],
        //   sectionEnd: true,
        // },
        {
          title: "Users",
          icon: "mdi-account-supervisor",
          // sectionEnd: true,
          permission: ["admin"],
          items: [{ title: "Manage Users", to: "/dashboard/users/manageusers/changeusersettings", icon: "mdi-account-multiple-plus", permissions: ["admin"] }],
        },
      ],
    };
  },
  mounted() {},
};
</script>

<style scoped>
.group-heading {
  /* background-color: #dfedff; */
  /* margin-bottom: 5px; */
  margin-top: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.group-heading-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.group-heading-text {
  font-weight: 700 !important;
}
.v-icon.v-icon {
  color: black;
}
::v-deep .v-label,
::v-deep .small-font,
::v-deep .small-font *,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
