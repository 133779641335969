import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
// Views & components
// import Home from "../views/dashboard/home/Home.vue";
// import BStd from "../views/dashboard/home/BStd.vue";
// import BPre from "../views/dashboard/home/BPre.vue";
// import BF50 from "../views/dashboard/home/BF50.vue";
// import Boards from "../views/dashboard/home/Boards.vue";
import DashboardMain from "../views/dashboard/Main.vue";

// Auth Views & Components
import Account from "../views/auth/Main.vue";
import Login from "../views/auth/Login.vue";
// import Register from "../views/auth/Register.vue";
import ForgotPassword from "../views/auth/Forgot.vue";
import Logout from "../views/auth/Logout.vue";
import RegisterConfirmEmail from "../views/auth/RegisterConfirmEmail.vue";
import ForgotEmail from "../views/auth/ForgotEmail.vue";
import PendingVerification from "../views/auth/PendingVerification.vue";
import PendingAdminVerification from "../views/auth/PendingAdminVerification.vue";

// Property Listings Views & Components
import Users from "../views/dashboard/users/Main.vue";
import Notifications from "../views/dashboard/users/Notifications.vue";

// Users Views & Components
import ChangeSettings from "../views/dashboard/users/ChangeSettings.vue";
import ChangePassword from "../views/dashboard/users/ChangePassword.vue";
import ChangeUserSettings from "../views/dashboard/users/ChangeUserSettings.vue";
import ViewActivity from "../views/dashboard/users/ViewActivity.vue";

// listing scribe pages
import CopyWriting from "../views/dashboard/home/CopyWriting";
import AccessByEmail from "../views/auth/AccessByEmail";
import AccessByEmailVerify from "../views/auth/AccessByEmailVerify.vue";

// blog writing components
import BlogWriting from "../views/dashboard/home/BlogWriting";
import Tender from "../views/dashboard/home/Tender";
import ChatDemo from "../views/dashboard/home/ChatDemo";

Vue.use(VueRouter);

const routes = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    children: [
      { path: "login", name: "Login", component: Login },
      // { path: "register", name: "Register", component: Register },
      { path: "forgotpassword", name: "Forgot Password", component: ForgotPassword },
      { path: "logout", name: "Logout", component: Logout },
      // { path: "registerconfirm", name: "RegisterConfirmEmail", component: RegisterConfirmEmail },
      { path: "forgotemail", name: "ForgotEmail", component: ForgotEmail },
      // { path: "pendingverification", name: "PendingVerification", component: PendingVerification },
      // { path: "PendingAdminVerification", name: "PendingAdminVerification", component: PendingAdminVerification },

      { path: "accessbyemail", name: "AccessByEmail", component: AccessByEmail },
      { path: "accessbyemailverify", name: "AccessByEmailVerify", component: AccessByEmailVerify },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardMain,
    children: [
      // {
      //   path: "home",
      //   name: "Home",
      //   component: Home,
      //   meta: { page: "Live Listings" },
      // },
      // {
      //   path: "livelistings",
      //   name: "Boards",
      //   component: Boards,
      //   meta: { page: "Live Listings" },
      // },
      {
        path: "copywriting",
        name: "CopyWriting",
        component: CopyWriting,
        meta: { page: "Generate Copy" },
      },
      {
        path: "blogwriting",
        name: "BlogWriting",
        component: BlogWriting,
        meta: { page: "Generate Blog" },
      },
      {
        path: "tender",
        name: "Tender",
        component: Tender,
        meta: { page: "Generate Tender Document" },
      },
      {
        path: "chatdemo",
        name: "ChatDemo",
        component: ChatDemo,
        meta: { page: "Chat Demo" },
      },
      // {
      //   path: "standardboard",
      //   name: "BStd",
      //   component: BStd,
      //   meta: { page: "Live Listings" },
      // },
      // {
      //   path: "premiumboard",
      //   name: "BPre",
      //   component: BPre,
      //   meta: { page: "Live Listings" },
      // },
      // {
      //   path: "founders50board",
      //   name: "BF50",
      //   component: BF50,
      //   meta: { page: "Live Listings" },
      // },
      {
        path: "users",
        name: "Users",
        component: Users,
        children: [
          { path: "manageaccount/changesettings", name: "ChangeSettings", component: ChangeSettings, meta: { page: "Edit Account" } },
          { path: "manageaccount/changepassword", name: "ChangePassword", component: ChangePassword, meta: { page: "Reset Password" } },
          { path: "manageusers/changeusersettings", name: "ChangeUserSettings", component: ChangeUserSettings, meta: { page: "Add Or Remove Users" } },
          { path: "manageusers/viewactivity", name: "ViewActivity", component: ViewActivity, meta: { page: "User Activity Logs" } },
          { path: "notifications", name: "Notifications", component: Notifications, meta: { page: "Notifications" } },
        ],
      },
    ],
  },
  // independednt pages
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("setHeading", to.meta.page);
  next();
});

export default router;
