const goblin_quotes = [
  "Goblins are mythical creatures that are often depicted as mischievous or malevolent.",
  "They are usually depicted as small and ugly with sharp teeth, pointy ears, and green skin.",
  "Goblins are often associated with dark places like caves and underground tunnels.",
  "They are said to have a talent for metalworking, especially in crafting swords and other weapons.",
  "In folklore, goblins are often blamed for the disappearance of household items, such as keys and socks.",
  "Goblins are believed to have originated in European folklore and mythology.",
  "The word 'goblin' comes from the Old French word 'gobelin'.",
  "In some cultures, goblins are believed to be the souls of unbaptized children.",
  "Goblins are often associated with Halloween and other spooky holidays.",
  "In J.R.R. Tolkien's 'The Hobbit,' the goblin king is a major antagonist.",
  "Goblins are a common enemy in many fantasy video games and tabletop RPGs.",
  "In some folklore, goblins are said to be able to shape shift into animals or other creatures.",
  "Goblins are often portrayed as having a particular fondness for gold and other shiny objects.",
  "Some goblins are said to be able to create illusions or cast spells.",
  "In some cultures, goblins are believed to be able to possess humans.",
  "Goblins are sometimes associated with the devil or demons.",
  "In some folklore, goblins are believed to be able to control the weather.",
  "Goblins are sometimes depicted as living in trees or other natural places.",
  "Some goblins are said to have the ability to speak with animals.",
  "In some legends, goblins are believed to be able to grant wishes.",
  "Goblins are often depicted as being afraid of sunlight or fire.",
  "In some cultures, goblins are believed to be able to steal people's souls.",
  "Goblins are sometimes associated with trickery and deception.",
  "In some folklore, goblins are believed to be able to control the minds of humans.",
  "Goblins are sometimes depicted as being able to regenerate lost limbs or other body parts.",
  "Some goblins are said to have the power of flight.",
  "In some cultures, goblins are believed to be able to change their size or shape at will.",
  "Goblins are often portrayed as being obsessed with cleanliness or order.",
  "In some folklore, goblins are said to be able to curse people.",
  "Goblins are often portrayed as being cowardly and easily frightened.",
];

const tones = [
  { tone: "Formal", desc: "serious, professional, respectful, polite, courteous" },
  { tone: "Informal", desc: "casual, relaxed, friendly, approachable, lighthearted" },
  { tone: "Sarcastic", desc: "ironic, tongue-in-cheek, wry, humorous, witty" },
  { tone: "Sympathetic", desc: "understanding, compassionate, caring, empathetic, supportive" },
  { tone: "Encouraging", desc: "positive, upbeat, optimistic, motivational, inspirational" },
  { tone: "Empathetic", desc: "concerned, understanding, kind, caring, supportive" },
  { tone: "Humorous", desc: "funny, amusing, entertaining, lighthearted, whimsical" },
  { tone: "Passionate", desc: "enthusiastic, excited, energetic, dynamic, fervent" },
  { tone: "Confident", desc: "assured, self-assured, bold, secure, optimistic" },
  { tone: "Assertive", desc: "decisive, forceful, self-assured, confident, bold" },
  { tone: "Persuasive", desc: "convincing, compelling, influential, persuasive, argumentative" },
  { tone: "Diplomatic", desc: "tactful, respectful, understanding, courteous, polite" },
  { tone: "Respectful", desc: "polite, courteous, considerate, dignified, professional" },
  { tone: "Polite", desc: "courteous, considerate, respectful, well-mannered, gentlemanly/ladylike" },
  { tone: "Supportive", desc: "encouraging, helpful, understanding, caring, kind" },
  { tone: "Understanding", desc: "empathetic, compassionate, sympathetic, supportive, considerate" },
  { tone: "Caring", desc: "compassionate, sympathetic, understanding, kind, considerate" },
  { tone: "Kind", desc: "considerate, compassionate, understanding, caring, respectful" },
  { tone: "Emotional", desc: "passionate, expressive, intense, heartfelt, dramatic" },
  { tone: "Conversational", desc: "relaxed, natural, friendly, approachable, casual" },
  { tone: "Businesslike", desc: "professional, efficient, practical, business-oriented, formal" },
  { tone: "Energetic", desc: "enthusiastic, lively, dynamic, upbeat, motivated" },
  { tone: "Cheerful", desc: "happy, upbeat, positive, joyful, optimistic" },
  { tone: "Witty", desc: "humorous, amusing, entertaining, clever, sarcastic" },
  { tone: "Inspiring", desc: "motivational, uplifting, encouraging, optimistic, positive" },
  { tone: "Motivational", desc: "inspiring, uplifting, encouraging, positive, optimistic" },
  { tone: "Respectful", desc: "polite, courteous, dignified, professional, well-mannered" },
  { tone: "Thoughtful", desc: "considerate, respectful, compassionate, understanding, caring" },
  { tone: "Considerate", desc: "respectful, understanding, caring, compassionate, polite" },
  { tone: "Heartfelt", desc: "sincere, genuine, emotional, compassionate, caring" },
];

module.exports = {
  tones,
  goblin_quotes,
};
