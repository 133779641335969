<template>
  <!-- View Layout -->
  <v-row class="justify-center my-3 mx-0">
    <!-- View header -->

    <v-overlay :value="loading" style="z-index: 20001" :opacity="0.9">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <div class="py-2 d-flex flex-column align-center justify-center">
          <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
          <span class="pa-2 text-center" style="line-height: 2em" v-html="loading_quote"></span>
        </div>
        <div class="py-2 d-flex align-baseline justify-center"></div>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="update_snackbar"
      :value="showSnackbar"
      timeout="2000"
      :vertical="true"
      app
      bottom
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template v-if="typeof snackbarText2 == 'string'">
          <div>{{ snackbarText2 }}</div>
        </template>
        <template v-else>
          <template v-for="(value, key) in snackbarText2">
            <div v-if="!['errors', 'failedDetails'].includes(key)" :key="key">{{ key }}: {{ value }}.</div>
          </template>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- Input Column -->
    <v-col cols="12" sm="12" md="7" class="my-1 pa-1">
      <template>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="history"
          :options.sync="options"
          :server-items-length="history_length"
          item-key="id"
          class="rounded-0 v-sheet v-card datatable-height"
          :show-select="false"
          :single-select="singleSelect"
          :show-expand="false"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          fixed-header
          dense
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 50] }"
          :loading="table_loading"
        >
          <!-- Actions slot -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left :open-delay="50">
              <template v-slot:activator="{ on }">
                <v-icon color="red" small class="mr-2" @click="remove_single_history(item.id)" v-on="on">mdi-trash-can</v-icon>
              </template>
              <span>Remove From History</span>
            </v-tooltip>
            <v-tooltip left :open-delay="50">
              <template v-slot:activator="{ on }">
                <v-icon color="green" small class="mr-2" @click="load_single_history(item)" v-on="on">mdi-arrow-up-circle</v-icon>
              </template>
              <span>Load History</span>
            </v-tooltip>
          </template>

          <!-- Date slot -->
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ format_date(item.createdAt) }}
          </template>

          <template v-slot:top>
            <!-- filters start -->
            <v-row class="px-5 py-1 align-baseline justify-center" no-gutters>
              <v-col cols="12" md="12" class="py-5">
                <v-row>
                  <!-- headings column + row -->
                  <v-col cols="12" md="12" class="mr-0 py-0 pb-2">
                    <h3>Start</h3>
                    <div class="py-1 sub-heading">Complete the below inputs to generate your Blog Post</div>
                  </v-col>
                  <!-- property information column -->
                  <v-col cols="12" md="7" class="mr-0 pr-0 py-0 d-flex flex-column align-baseline justify-start">
                    <div class="d-flex flex-row align-baseline justify-space-between w-100 ma-0 pa-0">
                      <h5 class="mb-4 text-left">Blog Outline</h5>
                      <h5 class="mb-4 d-flex flex-row align-baseline justify-space-around">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="blue" dense small v-bind="attrs" v-on="on" class="mr-1">mdi-information-outline</v-icon>
                          </template>
                          <span>Include or exclude the field value from the generated blog</span>
                        </v-tooltip>
                        Include
                      </h5>
                    </div>
                    <!-- address field -->

                    <!-- configuration field -->
                    <div class="d-flex flex-row align-baseline justify-space-around" style="width: 100%">
                      <div class="d-flex flex-row align-baseline justify-space-around" style="width: 100%">
                        <v-text-field v-model="blog.theme" placeholder="Theme" class="lc-input-fields rounded-0" hide-details dense solo>
                          <v-tooltip bottom slot="prepend">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                            </template>
                            <span>Theme: a sentence input. i.e. 10 reason to get married at a golf course</span>
                          </v-tooltip>
                        </v-text-field>
                        <v-checkbox v-model="include_field.configuration" class="ma-0 pa-0" slot="append" hide-details dense solo></v-checkbox>
                      </div>
                    </div>

                    <!-- features field -->
                    <div class="d-flex flex-row align-baseline justify-space-around" style="width: 100%">
                      <v-combobox
                        append-icon=""
                        label="Key Points"
                        v-model="blog.features"
                        clearable
                        dense
                        multiple
                        small-chips
                        solo
                        class="lc-input-fields"
                        deletable-chips
                        hide-details
                      >
                        <v-tooltip bottom slot="prepend">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                          </template>
                          <span>Additional features: you can add multiple features in the field, type & press enter for every single feature</span>
                        </v-tooltip>
                      </v-combobox>
                      <v-checkbox v-model="include_field.features" class="ma-0 pa-0" slot="append" hide-details dense solo></v-checkbox>
                    </div>
                    <!-- features field -->
                    <div class="ma-0 pa-0 d-flex flex-column align-baseline justify-start" style="width: 100%">
                      <v-switch
                        class="my-2 py-0 mr-5 d-flex flex-row align-baseline"
                        color="primary"
                        v-model="blog.title"
                        hide-details
                        flat
                        dense
                        :label="blog.title ? `Generate Title` : 'Don\'t Generate Title '"
                      >
                        <v-tooltip bottom slot="prepend">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                          </template>
                          <span>Generate title or not</span>
                        </v-tooltip>
                      </v-switch>

                      <!-- Social Post -->
                      <v-switch
                        class="my-2 py-0 d-flex flex-row align-baseline"
                        color="primary"
                        v-model="blog.social_post"
                        hide-details
                        flat
                        dense
                        :label="blog.social_post ? `Generate Social Post` : 'Don\'t Generate Social Post'"
                      >
                        <v-tooltip bottom slot="prepend">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                          </template>
                          <span>Toggle generating Social Post</span>
                        </v-tooltip>
                      </v-switch>
                      <!-- excerpt -->
                      <v-switch
                        class="my-2 py-0 d-flex flex-row align-baseline"
                        color="primary"
                        v-model="blog.excerpt"
                        hide-details
                        flat
                        dense
                        :label="blog.excerpt ? `Generate Excerpt` : 'Don\'t Generate Excerpt'"
                      >
                        <v-tooltip bottom slot="prepend">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                          </template>
                          <span>Generate Excerpt</span>
                        </v-tooltip>
                      </v-switch>

                      <!-- Stock Photo Links -->
                      <v-switch
                        class="my-2 py-0 d-flex flex-row align-baseline"
                        color="primary"
                        v-model="blog.photo_links"
                        hide-details
                        flat
                        dense
                        :label="blog.photo_links ? `Include Stock Photo Links` : 'Exclude Stock Photo Links'"
                      >
                        <v-tooltip bottom slot="prepend">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                          </template>
                          <span>Generate Excerpt</span>
                        </v-tooltip>
                      </v-switch>
                    </div>
                  </v-col>

                  <!-- settings column -->
                  <v-col cols="12" md="5" class="mr-0 pr-0 py-0 settings-div">
                    <h5 class="mb-4 text-center" style="width: 100%">Settings</h5>
                    <div class="d-flex flex-column align-baseline justify-space-between h-80">
                      <div class="w-100 settings-inner-div rounded py-4 px-2 mb-3">
                        <v-range-slider
                          v-model="settings.length"
                          label="Words"
                          :min="100"
                          :max="800"
                          :step="50"
                          thumb-label
                          hide-details
                          class="d-flex flex-row align-baseline justify-space-between"
                        >
                          <template v-slot:prepend>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span>Length in words</span>
                            </v-tooltip>
                          </template>
                        </v-range-slider>

                        <v-slider
                          v-model="settings.complexity"
                          label="Variety"
                          step="0.5"
                          :min="0"
                          :max="10"
                          thumb-label
                          hide-details
                          class="d-flex flex-row align-baseline justify-space-between pr-2"
                        >
                          <template v-slot:prepend>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span>How regularly Gerald pulls out his thesaurus to find different words to use in a sentence</span>
                            </v-tooltip>
                          </template>
                          <template v-slot:append>
                            <span>/10</span>
                          </template>
                        </v-slider>

                        <v-slider
                          v-model="settings.enthusiasm"
                          label="Creativity"
                          step="0.5"
                          :min="0"
                          :max="10"
                          thumb-label
                          hide-details
                          class="d-flex flex-row align-baseline justify-space-between pr-2"
                        >
                          <template v-slot:prepend>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span>How creatively Gerald puts words together in a sentence</span>
                            </v-tooltip>
                          </template>
                          <template v-slot:append>
                            <span>/10</span>
                          </template>
                        </v-slider>

                        <!-- length selection -->
                        <!-- <div class="d-flex flex-row align-baseline justify-space-around" style="width: 100%">
                          <v-autocomplete
                            append-icon=""
                            label="Select Length"
                            v-model="settings.length"
                            dense
                            small-chips
                            solo
                            class="lc-input-fields text-capitalize"
                            deletable-chips
                            hide-details
                            :items="length_options"
                            placeholder="Select Length"
                          >
                            <v-tooltip bottom slot="prepend">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span>Estimated length preference selector</span>
                            </v-tooltip>
                          </v-autocomplete>
                        </div> -->
                        <!-- tone selection -->
                        <div class="d-flex flex-row align-baseline justify-space-around" style="width: 100%">
                          <v-combobox
                            append-icon=""
                            label="Tone Selection"
                            v-model="settings.tone"
                            clearable
                            dense
                            multiple
                            small-chips
                            solo
                            class="lc-input-fields"
                            deletable-chips
                            hide-details
                            :items="tones"
                            item-text="tone"
                            item-value="tone"
                            :return-object="false"
                          >
                            <v-tooltip bottom slot="prepend">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span>Tone Selector (upto 4 tones)</span>
                            </v-tooltip>
                          </v-combobox>
                        </div>

                        <!-- language -->
                        <div class="d-flex flex-row align-baseline justify-space-around" style="width: 100%">
                          <v-select
                            append-icon=""
                            label="Language"
                            v-model="settings.language"
                            dense
                            small-chips
                            solo
                            class="lc-input-fields"
                            hide-details
                            :items="['US English', 'AU English']"
                          >
                            <v-tooltip bottom slot="prepend">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span>Tone Selector (upto 4 tones)</span>
                            </v-tooltip>
                          </v-select>
                        </div>
                        <!-- language -->
                        <div class="d-flex flex-row align-baseline justify-space-around" style="width: 100%">
                          <v-select
                            append-icon=""
                            label="Assistant"
                            v-model="settings.model"
                            dense
                            small-chips
                            solo
                            class="lc-input-fields"
                            hide-details
                            :items="model_options"
                          >
                            <v-tooltip bottom slot="prepend">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="blue" dense small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <span>Model Selector - Choose more advanced version for better understanding of subject and output.</span>
                            </v-tooltip>
                          </v-select>
                        </div>

                        <!-- action buttons section -->
                        <v-btn color="primary" class="py-1 mx-2 my-1 rounded-0" @click="setDefaultSettings()" small outlined>
                          <v-icon class="mr-1" small>mdi-restore</v-icon>
                          Reset Settings
                        </v-btn>
                      </div>
                      <div class="w-100 px-2 d-flex flex-column align-end justify-end">
                        <v-btn color="primary" class="py-1 mx-2 mb-1 rounded-0" @click="setDefaults()" small outlined>
                          <v-icon class="mr-1" small>mdi-reload</v-icon>
                          Start New
                        </v-btn>
                        <v-btn color="primary" class="py-1 mx-2 mb-1 rounded-0" @click="submit_request()" small outlined>
                          <!-- :disabled="!history_addresses().includes(property.address)" -->
                          <v-icon class="mr-1" small>mdi-redo</v-icon>
                          Regenerate
                        </v-btn>
                        <v-btn color="primary" class="py-1 mx-2 mb-1 rounded-0" @click="submit_request()" small outlined>
                          <!-- :disabled="history_addresses().includes(property.address)" -->
                          <v-icon class="mr-1" small>mdi-check</v-icon>
                          Generate
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- History row -->
            <v-row class="px-5 py-1 align-baseline justify-center" no-gutters>
              <v-col cols="12" md="12" class="mr-0 py-0">
                <h5 class="mb-4 text-left" style="width: 100%">
                  History
                  <!-- <small>(Coming Soon)</small> -->
                </h5>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </template>
    </v-col>

    <!-- Result column -->
    <v-col cols="12" sm="12" md="5" class="d-flex justify-center my-1 pa-1 h-100">
      <v-card class="px-5 mx-0 rounded-0 h-100" style="height: fit-content; width: 100%">
        <div class="d-flex flex-row justify-space-between align-center">
          <h5 class="mx-1 my-3 text-left">Result</h5>
          <h5 class="mx-1 my-3 text-left">Remaining Tokens: {{ user_tokens }}</h5>
        </div>
        <div ref="form">
          <v-textarea v-if="result_editable" id="content" v-model="result" rows="20" :counter="160" required hide-details dense solo class="my-3"></v-textarea>
          <template v-else>
            <v-alert class="result_alert" border="left" colored-border color="deep-purple accent-4" elevation="1">
              Double-click or select any word to be avoided in next revision of copy.
            </v-alert>
            <pre class="result_div" v-html="cresult" @mouseup="highlightSelected"></pre>
          </template>
        </div>
        <div class="d-flex justify-space-between align-center my-1">
          <div>
            <v-btn v-for="(l, i) of stock_photo_links" :key="l + i" color="primary" class="py-1 mx-2 mb-1 rounded-0" @click="visit_link(l.l)" small outlined>
              <v-icon class="mr-1" small>mdi-link</v-icon>
              {{ l.n }}
            </v-btn>
          </div>
          <h5 class="mx-1 my-3 text-left">Keywords To Exclude: {{ excluded.length }}</h5>
        </div>
        <div class="d-flex justify-center my-1 pa-1 h-100">
          <!-- copy button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2 pa-2" color="blue" dense small v-bind="attrs" v-on="on" @click="copyResult">mdi-content-copy</v-icon>
            </template>
            <span>Copy Results</span>
          </v-tooltip>

          <!-- clear highlight button -->
          <v-tooltip bottom v-if="!result_editable">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2 pa-2" color="blue" dense small v-bind="attrs" v-on="on" @click="excluded = []">mdi-cancel</v-icon>
            </template>
            <span>Clear Highlighted</span>
          </v-tooltip>

          <!-- change mode button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2 pa-2" color="blue" dense small v-bind="attrs" v-on="on" @click="result_editable = !result_editable">
                mdi-square-edit-outline
              </v-icon>
            </template>
            <span v-if="result_editable">Switch to exclude mode</span>
            <span v-else>Switch to editable mode</span>
          </v-tooltip>

          <!-- change mode button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2 pa-2" color="blue" dense small v-bind="attrs" v-on="on" @click="downloadDoc">mdi-download</v-icon>
            </template>
            <span>Export as doc</span>
          </v-tooltip>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
let data = require("./data");
let fns = require("./functions");

export default {
  name: "CopyWriting",
  data: () => ({
    // Copy Writing Fields
    settings: {
      length: [200, 400],
      complexity: 8,
      enthusiasm: 8,
      tone: [],
      language: "AU English",
      model: "gpt-3.5",
    },
    length_options: ["short", "long"],
    blog: {
      theme: null,
      features: [],
      title: true,
      excerpt: true,
      social_post: true,
      photo_links: true,
    },
    include_field: {
      theme: true,
      features: true,
      title: true,
      excerpt: true,
      social_post: true,
      photo_links: true,
    },
    result: "",
    busy: false,
    suggestions: [],
    request: {},

    // new fields
    history: [],
    history_length: 0,
    options: {
      page: 1,
      itemsPerPage: 10,
      itemsLength: 0,
      sortBy: ["id"],
      sortDesc: [true],
    },
    requestSent: false,
    suggestionRequestSent: false,
    loading_quote_default: `Gerald just back from his unannounced coffee break. <br /> Please wait while he generates your blog right now <br /> In the meantime, there are some interesting facts about goblins: <br /> `,
    loading_quote: "",

    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    // table expansion
    expanded: [],
    singleExpand: false,

    // Table selection
    singleSelect: false,
    selected: [],
    table_loading: false,

    excluded: [],
    cresult: "",
    result_editable: true,

    user_tokens: null,

    stock_photo_sources: [
      { l: "https://www.shutterstock.com/search/", n: "Shutterstock" },
      { l: "https://unsplash.com/s/photos/", n: "Unsplash" },
    ],
    stock_photo_links: [],
    tones: data.tones,
    goblin_quotes: data.goblin_quotes,
    model_options: [
      { text: "GPT4 (Advance)", value: "gpt-4" },
      { text: "GPT3.5", value: "gpt-3.5" },
      { text: "GPT3", value: "gpt-3" },
    ],
  }),
  computed: {
    headers() {
      return [
        { text: "Theme", value: "address", align: "start", sortable: true },
        { text: "Date & Time", value: "createdAt", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    loading() {
      return this.requestSent;
    },
  },
  watch: {
    result(newval) {
      this.cresult = newval;
    },
    excluded(newval) {
      let htext = this.result;
      for (let kw of newval) htext = htext.replace(new RegExp("\\b" + kw + "\\b", "gi"), `<span class='highlighted'>${kw}</span>`);
      this.cresult = htext;
    },
    // new fields
    options() {
      this.loadHistory();
    },
    requestSent(newval) {
      if (newval) this.loading_quote = this.loading_quote_default;
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await this.get_user_tokens();
        await this.loadHistory();
        this.change_loading_quote();
      } catch (error) {}
    },
    async submit_request() {
      try {
        if (this.requestSent) return;
        this.requestSent = true;
        if (!this.blog.theme) throw new Error("Please provide a valid theme");
        if (!this.settings.model) throw new Error("Please select a model to continue");

        // validate address field (street + suburb + state + postcode)
        // return console.log(this.settings.tone)

        let property = {};
        await new Promise((r) => setTimeout(r, 100));
        for (let [k, v] of Object.entries(this.blog)) {
          if (typeof v == "boolean") property[k] = v;
          else if (v && v.length && this.include_field[k]) {
            if (["string", "number"].includes(typeof v)) property[k] = v;
            else if (Array.isArray(v))
              property[k] = Object.assign(
                [],
                v.filter((a) => a)
              );
          }
        }

        this.stock_photo_links = [];

        console.log(property);
        console.log(this.settings);
        // this.requestSent = false;
        // return;

        let res = await this.axios.post(`/api/users/getblogdata`, {
          settings: this.settings,
          property: property,
          include_fields: this.include_field,
          exclude_keywords: this.excluded,
        });
        // console.log(res.data);
        this.requestSent = false;
        // console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        this.result = res.data.result.trim();
        this.request = res.data.options;

        // setting links
        if (this.include_field.photo_links && this.blog.theme)
          for (let s of this.stock_photo_sources) this.stock_photo_links.push({ l: `${s.l}${this.blog.theme.trim().replace(/\s/g, "-")}`, n: s.n });

        await new Promise((r) => setTimeout(r, 3000));
        await this.loadHistory();
        await this.get_user_tokens();
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Response could not be generated");
      }
    },
    setDefaults() {
      this.settings = {
        length: [200, 400],
        complexity: 8,
        enthusiasm: 8,
        tone: [],
        language: "AU English",
        model: "gpt-3.5",
      };
      this.blog = {
        theme: null,
        features: [],
        title: true,
        excerpt: true,
        social_post: true,
        photo_links: true,
      };
      this.result = "";
      this.request = {};
      this.excluded = [];
      this.stock_photo_links = [];
    },
    setDefaultSettings() {
      this.settings = {
        length: [200, 400],
        complexity: 8,
        enthusiasm: 8,
        tone: [],
        language: "AU English",
        model: "gpt-3.5",
      };
    },

    async copyResult() {
      try {
        if (!this.result) return;
        await navigator.clipboard.writeText(this.result);
        this.snackbarShow(true, "Text Copied", "");
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "Text could not be copied to clipboard, kindly try doing it manually");
      }
    },
    async loadHistory() {
      try {
        // checking for other requests
        if (this.requestSent) return;
        this.table_loading = true;
        await new Promise((r) => setTimeout(r, 200));
        // sending request for history
        let res = await this.axios.post("/api/users/gethistory", { options: this.options, type: "blog" });
        console.log(res.data);
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);

        this.history = res.data.history;
        this.history_length = res.data.count;
      } catch (error) {
        console.log(error);
        this.table_loading = false;
        this.snackbarShow(true, "Error", "History could not be loaded: check your internet connection");
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    update_snackbar(a) {
      this.showSnackbar = a;
    },
    print(e) {
      console.log(e);
    },
    async load_single_history(h) {
      try {
        this.setDefaults();
        this.table_loading = true;
        let r = JSON.parse(h.request);
        if (!r.settings.length || !Array.isArray(r.settings.length)) r.settings.length = [200, 400];
        for (let [k, v] of Object.entries(r.property)) this.blog[k] = v;
        for (let [k, v] of Object.entries(r.settings)) this.settings[k] = v;
        for (let [k, v] of Object.entries(r.include_fields)) this.include_field[k] = v;
        this.excluded = r.exclude ? Object.assign([], r.exclude) : [];
        this.result = h.response.trim();

        // creating photo links
        if (this.include_field.photo_links && this.blog.theme)
          for (let s of this.stock_photo_sources) this.stock_photo_links.push({ l: `${s.l}${this.blog.theme.trim().replace(/\s/g, "-")}`, n: s.n });

        this.table_loading = false;
      } catch (error) {
        console.log(error);
        this.table_loading = false;
      }
    },
    async remove_single_history(id) {
      try {
        this.table_loading = true;
        let res = await this.axios.post("/api/users/removehistory", { id });
        console.log(res.data);
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);
        await this.loadHistory();
      } catch (error) {
        console.log(error);
        this.table_loading = false;
      }
    },
    async get_user_tokens() {
      try {
        let res = await this.axios.get("/api/users/getusertokens");
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        this.user_tokens = res.data.user_tokens;
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "User tokens could not be generated");
      }
    },
    format_date(date) {
      if (!date) return date;
      return date.replace("T", " ").replace(/\.[^.]*$/gi, "");
    },
    history_addresses() {
      return this.history.map((h) => h.address);
    },
    downloadDoc() {
      let file_name = this.blog.theme ? this.blog.theme : "Blog";
      fns.download_doc(this.result, file_name);
    },
    highlightSelected() {
      let text = window.getSelection().toString().toLowerCase().trim();
      let keywords = text.split(" ");
      for (let kw of keywords) if (kw && kw.trim().length > 2 && !this.excluded.includes(kw)) this.excluded.push(kw.trim());
    },
    visit_link(l) {
      window.open(l);
    },
    change_loading_quote() {
      setInterval(() => {
        if (this.requestSent) {
          let max = this.goblin_quotes.length - 1;
          let min = 0;
          let rand = Math.floor(min + Math.random() * (max - min + 1));
          this.loading_quote = this.goblin_quotes[rand];
        }
      }, 7000);
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.settings-div .v-btn {
  text-transform: initial;
  width: 160px;
  float: right;
}
.settings-div .v-btn .v-icon {
  width: 20px;
}
/* ::v-deep .v-input--selection-controls__ripple {

    margin: 0px !important;
} */
::v-deep .v-input__slider label {
  width: 75px;
}
.datatable-height {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.h-80 {
  height: 80%;
}
.settings-inner-div {
  border: 1px solid #21a6f6c2;
}
.highlighted {
  background-color: yellow;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.result_alert {
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.sub-heading {
  font-size: 14px;
  font-weight: 500;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}
</style>
