<template>
  <v-row class="justify-center ma-1">
    <!-- Snackbar for small info alerts -->
    <v-snackbar :value="showSnackbar" timeout="-1" absolute app top right shaped style="opacity: 1; z-index: 999" elevation="24">
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 " style="font-size: 13px">
        <div>{{ snackbarText2 }}</div>
      </div>
      <v-btn style="float: right" class="mt-2" small color="primary" text @click="showSnackbar = false">Close</v-btn>
    </v-snackbar>

    <v-col cols="12" sm="12" md="12" class="ma-1 pa-1">
      <v-card class="px-0 py-5 ma-0 d-flex flex-column justify-space-between align-center" style="height: 90vh">
        <!-- Message Display-->
        <div class="messages-section w-100 d-flex flex-column justify-start align-center">
          <div class="w-50">
            <v-select
              hide-details
              v-model="settings.chatbot"
              :items="all_chatbots"
              label="Select Chatbot"
              solo
              dense
              class="text-sm dialog-select"
              item-text="name"
              item-value="id"
            ></v-select>
          </div>
          <div class="messages-inner-wrapper w-70 py-3" id="message-scroll" ref="message_scroll">
            <div
              v-for="(m, i) of messages"
              :key="i"
              class="py-3 pr-8 pl-4 message d-flex flex-row justify-space-between align-baseline"
              :class="{ user_bg: m.role !== 'assistant' }"
            >
              <div class="message_text" v-html="format_message(m.content)"></div>

              <v-tooltip bottom v-if="m.tokens">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ma-5" small v-bind="attrs" v-on="on">mdi-information</v-icon>
                </template>
                <div>
                  <span class="ml-3" small v-if="m.tokens">Total tokens: {{ m.tokens }}</span>
                  <br />
                  <span class="ml-3" small v-if="m.context_ids && m.context_ids.length">Context Ids: {{ m.context_ids.join(",") }}</span>
                </div>
              </v-tooltip>
            </div>
          </div>
        </div>

        <!-- Input field & Settings -->
        <div class="input-section d-flex flex-row align-baseline justify-space-around w-70">
          <v-text-field
            v-model="message.content"
            hide-details
            dense
            solo
            @keyup.enter="send_message"
            class="message_input"
            placeholder="Type something to start..."
          >
            <template v-slot:append>
              <!-- send button & loading sign -->
              <v-progress-circular v-if="busy" :size="16" indeterminate color="primary"></v-progress-circular>
              <v-icon v-else @click="send_message()" class="setting-icons" small>mdi-send</v-icon>
              <!-- settings menu -->
              <v-menu offset-y offset-x top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="setting-icons ml-2" v-bind="attrs" v-on="on" small>mdi-cog</v-icon>
                </template>
                <v-list dense class="pa-0 ma-0 settings-menu">
                  <v-list-item @click="set_defaults()">
                    <v-list-item-title>Start New</v-list-item-title>
                  </v-list-item>
                  <v-list-item disabled>
                    <v-list-item-title>Add Direct Context</v-list-item-title>
                  </v-list-item>
                  <v-list-item disabled>
                    <v-list-item-title>Add Context Website</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="upload_dialog = true">
                    <v-list-item-title>Add Context</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- info icon -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="setting-icons ml-2" small v-bind="attrs" v-on="on">mdi-information</v-icon>
                </template>
                <div>Total tokens: {{ total_tokens }}</div>
              </v-tooltip>
            </template>
          </v-text-field>
        </div>

        <v-dialog width="600" class="upload-dialog">
          <v-card class="dialog-card">
            <v-card-title class="mb-5 grey lighten-2">Add Your Data</v-card-title>

            <v-card-text>
              <div>
                <!-- New or existing -->
                <v-select
                  v-model="chatbot.newbot"
                  hide-details
                  dense
                  solo
                  @keyup.enter="send_message"
                  class="message_input my-2"
                  placeholder="Select Chatbot"
                  :items="[1, 2, 3]"
                ></v-select>
                <!-- Select Existing -->
                <v-select
                  v-if="!chatbot.newbot"
                  v-model="chatbot.name"
                  hide-details
                  dense
                  solo
                  @keyup.enter="send_message"
                  class="message_input my-2"
                  placeholder="Select Chatbot"
                  :items="[1, 2, 3]"
                ></v-select>
                <!-- Enter new  -->
                <v-text-field
                  v-if="chatbot.newbot"
                  v-model="chatbot.name"
                  hide-details
                  dense
                  solo
                  @keyup.enter="send_message"
                  class="message_input my-2"
                  placeholder="Select Chatbot"
                ></v-text-field>
              </div>
              <div>
                <input type="file" ref="fileInput" @change="uploadFile" style="display: none" />
                <v-btn color="primary" class="mx-2" small @click="$refs.fileInput.click()">Choose File</v-btn>
                <span v-if="file && file.name">{{ file.name }}</span>
                <v-btn color="primary" class="mx-2" small @click="submitFile">Upload</v-btn>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="" text @click="upload_dialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="upload_dialog" max-width="500">
          <v-card>
            <v-card-title class="text--primary text-center w-100">Add Custom Data</v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <!-- chatbot settings section -->
                  <v-col cols="12" sm="12" class="pb-0">
                    <h4 class="pb-3">Chatbot Settings</h4>
                  </v-col>

                  <!-- selecting existing o new chatbot -->
                  <v-col cols="12" sm="12" class="py-0 pb-1">
                    <v-select
                      hide-details
                      v-model="upload_settings.existing_chatbot"
                      :items="chatbot_options"
                      label="Select Mode..."
                      solo
                      dense
                      class="text-sm dialog-select"
                    ></v-select>
                  </v-col>

                  <!-- existing chatbot -->
                  <v-col cols="12" sm="12" class="py-1 d-flex flex-row align-baseline justify-space-around" v-if="upload_settings.existing_chatbot">
                    <v-select
                      hide-details
                      v-model="upload_settings.selected_chatbot"
                      :items="user_chatbots"
                      label="Select Chatbot"
                      solo
                      dense
                      class="text-sm dialog-select"
                      item-text="name"
                      item-value="id"
                    ></v-select>

                    <!-- Data Saving mode -->
                    <v-select
                      hide-details
                      v-model="upload_settings.remove_existing_data"
                      :items="saving_options"
                      label="Select Data Option"
                      solo
                      dense
                      class="text-sm pl-2 dialog-select"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" v-if="!upload_settings.existing_chatbot" class="py-1">
                    <v-text-field
                      hide-details
                      label="Enter new chatbot name"
                      v-model="upload_settings.new_chatbot_name"
                      solo
                      dense
                      class="text-sm dialog-textfield"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="upload_settings.existing_chatbot && show_script" class="py-1">
                    <v-textarea
                      v-model="deployment_script"
                      rows="4"
                      hide-details
                      dense
                      solo
                      class="my-3 script-textarea"
                      readonly
                      no-resize
                      append-icon="mdi-content-copy"
                      @click:append="copyScript"
                    ></v-textarea>
                  </v-col>

                  <!-- data upload settings -->
                  <v-col cols="12" sm="12" class="pb-0">
                    <h4 class="pb-3">Data Settings</h4>
                  </v-col>

                  <!-- selecting existing o new chatbot -->
                  <v-col cols="12" sm="12" class="py-0 pb-1">
                    <v-select
                      hide-details
                      v-model="upload_settings.type"
                      :items="upload_type_options"
                      label="Select Upload Type..."
                      solo
                      dense
                      class="text-sm dialog-select"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" class="py-1">
                    <v-file-input
                      v-if="upload_settings.type == 'file'"
                      v-model="file"
                      label="Upload file"
                      accept=".csv,.json,.zip"
                      solo
                      dense
                      class="text-sm dialog-file-input"
                      counter
                      show-size
                      prepend-icon="mdi-file-arrow-up-down-outline"
                    ></v-file-input>
                    <v-text-field
                      v-if="upload_settings.type == 'scrape'"
                      label="Enter website link"
                      v-model="upload_settings.website_link"
                      solo
                      dense
                      class="text-sm dialog-textfield"
                      prepend-icon="mdi-link-plus"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-1 pr-5 d-flex flex-row align-center justify-end">
                    <v-btn color="success" small @click="setScript()" prepend-icon="mdi-link-plus" outlined>
                      <v-icon right dark class="mr-1 ml-1">mdi-eye</v-icon>
                      Script
                    </v-btn>
                    <v-btn class="mx-2" color="primary" small @click="saveDialog" prepend-icon="mdi-link-plus" outlined>
                      <v-progress-circular v-if="busy" :size="16" indeterminate color="primary"></v-progress-circular>
                      <v-icon v-else right dark class="mr-1 ml-0">mdi-cloud-upload</v-icon>
                      Save
                    </v-btn>
                    <v-btn color="warning" small @click="closeDialog" outlined>
                      <v-icon right dark class="mr-1 ml-0">mdi-cancel</v-icon>
                      Cancel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const moment = require("moment");

export default {
  name: "CopyWriting",
  data: () => ({
    data: {
      from: "",
    },
    message: {
      role: "user",
      content: "",
      //   datetime: "",
    },
    context: "",
    settings: {
      chatbot: null,
    },
    messages: [],
    // message_chatbot: null,

    // snackbar fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",

    busy: false,
    context_length: 8,

    // file upload section

    project: {
      org: "NCC",
      chatbot: "pharmacy",
    },
    chatbot: {
      newbot: false,
      name: "",
    },

    // chatgpt form
    upload_dialog: false,
    upload_settings: {
      existing_chatbot: true,
      selected_chatbot: null,
      remove_existing_data: true,
      new_chatbot_name: null,
      website_link: null,
      type: "file",
    },
    file: null,

    chatbot_options: [
      { text: "Existing", value: true },
      { text: "New", value: false },
    ],
    user_chatbots: [
      { name: "Chatbot 1", id: 1 },
      { name: "Chatbot 2", id: 2 },
      { name: "Chatbot 3", id: 3 },
    ], //will be fecthed as per user
    saving_options: [
      { text: "Remove Existing Data", value: true },
      { text: "Add To Existing Data", value: false },
    ],
    upload_type_options: [
      { text: "File Upload (.zip)", value: "file" },
      { text: "Website (Link)", value: "scrape" },
    ],
    all_chatbots: [],

    show_script: false,
    deployment_script: null,
  }),
  computed: {
    total_tokens() {
      let tokens = 0;
      for (let m of this.messages) if (m.tokens) tokens += m.tokens;
      return tokens;
    },
  },
  watch: {
    "settings.chatbot"() {
      this.set_defaults();
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.scroll_to_bottom();
        await this.get_user_chabots();
        await this.get_all_chabots();
      } catch (error) {
        console.log(error);
      }
    },
    async set_defaults() {
      this.messages = [];
      this.message = { role: "user", content: "" };
    },
    async send_message() {
      try {
        if (this.busy || !this.message.content) return;
        if (!this.settings.chatbot) throw new Error("Select a chatbot to interact with");
        this.busy = true;

        // let messages = Object.assign([], this.messages.slice(-this.context_length));
        let messages = this.messages.map((m) => Object.assign({}, m));
        let message = Object.assign({}, this.message);
        this.messages.push(message);
        this.message.content = "";
        this.scroll_to_bottom();

        // sanitizing request objects
        if (message.tokens) delete message.tokens;
        if (message.context_ids) delete message.context_ids;

        // resolving chatbot hash
        const cb_id = this.settings.chatbot;
        const chatbot = this.all_chatbots.filter((c) => c.id == cb_id);
        if (!chatbot.length) throw new Error("Invalid chatbot");
        const chatbot_hash = chatbot[0].hash;

        let res = await this.axios.post("/api/openai/getresponse", {
          settings: { chatbot: chatbot_hash },
          message,
          messages,
        });
        // console.log(res.data);
        this.busy = false;
        if (!res.data.success) throw new Error(res.data.message);

        this.messages.push({ ...res.data.response.message, tokens: res.data.response.tokens, context_ids: res.data.response.context_ids });
        await new Promise((r) => setTimeout(r, 500));
        this.scroll_to_bottom();
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.messages.push({ role: "assistant", content: "Response could not be generated" });
        this.scroll_to_bottom();
        this.snackbarShow(true, "Error", error.message ? error.message : "Response could not be generated");
      }
    },
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    async scroll_to_bottom() {
      let el = this.$refs.message_scroll;
      el.scrollTop = el.scrollHeight;
    },

    load_seed_data() {
      for (let i = 1; i < 20; i++) {
        this.messages.push({
          role: "user",
          content: `${i} Similarly, in a more complex sentence like "After eating the sandwich, she washed the dishes", the GPT model can understand that "she" is the subject of the sentence.`,
          //   datetime: moment().format("YYYY-MM-DD"),
        });
      }
    },
    print(e) {
      console.log(e);
    },
    format_message(msg) {
      return msg.replace(/\n/g, "<br>");
    },
    uploadFile() {
      this.file = this.$refs.fileInput.files[0];
    },
    async get_all_chabots() {
      try {
        this.busy = true;
        let res = await this.axios.get("/api/openai/getallchatbots");
        this.busy = false;
        if (!res.data.success) throw new Error(res.data.message);
        this.all_chatbots = res.data.chatbots;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Response could not be generated");
      }
    },
    async get_user_chabots() {
      try {
        this.busy = true;
        let res = await this.axios.get("/api/openai/getuserchatbots");
        this.busy = false;
        if (!res.data.success) throw new Error(res.data.message);
        this.user_chatbots = res.data.chatbots;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Response could not be generated");
      }
    },
    async submitFile() {
      try {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("chatbot", this.project.chatbot);

        let response = await this.axios.post("/api/openai/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log(response.data);
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Response could not be generated");
      }
    },

    async saveDialog() {
      try {
        this.showSnackbar = false;
        let settings = Object.assign({}, this.upload_settings);

        // validating data
        if (settings.existing_chatbot && !settings.selected_chatbot) throw new Error("Select a chatbot to continue or select 'new'.");
        if (!settings.existing_chatbot && !settings.new_chatbot_name) throw new Error("Enter name of new chatbot to be created.");
        if (settings.type == "file" && !this.file) throw new Error("Select a file to upload.");

        const urlRegex = /(https?:\/\/)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5})(:[0-9]{1,5})?(\/.*)?/i;
        if (settings.type == "scrape" && (!settings.website_link || !urlRegex.test(settings.website_link)))
          throw new Error("Kindly enter a valid URL to continue.");

        // preparing for upload
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("settings", JSON.stringify(settings));
        this.busy = true;
        // sending request
        let res = await this.axios.post("/api/openai/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.busy = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        this.snackbarShow(true, "Info", "Data saved successfully");
        this.get_user_chabots();
        this.get_all_chabots();
        // this.closeDialog();
        // response (socket?)
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Data could not be uploaded");
      }
    },

    setScript() {
      try {
        const id = this.upload_settings.selected_chatbot;
        if (!this.upload_settings.existing_chatbot || !id) throw new Error("No chatbot selected");
        let chatbot = this.all_chatbots.filter((c) => c.id == id);
        chatbot = chatbot[0];
        const hash = chatbot.hash;
        const src = "https://bot.busi.chat/chatbot.js";

        // vuejs does not allow direct script creation => working around with vanilla js
        let script = document.createElement("script");
        script.setAttribute("src", src);
        script.setAttribute("bot", hash);
        script = script.outerHTML;

        this.deployment_script = script;
        this.show_script = !this.show_script;
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Select a chatbot to show script");
      }
    },
    async copyScript() {
      try {
        if (!this.deployment_script) return;
        await navigator.clipboard.writeText(this.deployment_script);
        this.snackbarShow(true, "Script Copied", "");
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "Text could not be copied to clipboard, kindly try doing it manually");
      }
    },
    closeDialog() {
      // this.upload_dialog = false;
      this.upload_settings = {
        existing_chatbot: true,
        selected_chatbot: null,
        remove_existing_data: true,
        new_chatbot_name: null,
        website_link: null,
        type: "file",
      };
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100% !important;
}
.w-70 {
  width: 70% !important;
}
.w-50 {
  width: 50% !important;
}
.messages-section {
  height: 90%;
}

.messages-inner-wrapper {
  height: 100%;
  overflow-y: scroll;
}
.message {
  border-bottom: 1px solid #e4e3e3;
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.messags_text {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  line-height: 1.7em;
}
.setting-icons {
  cursor: pointer;
}

/* scroll styling */
#message-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#message-scroll::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}
.user_bg {
  background-color: #f8f8f8;
}
::v-deep .message_input input {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
}
.settings-menu {
  font-size: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  color: grey;
  cursor: pointer;
}
.dialog-card * {
  font-size: 13px;
}
.dialog-card .v-btn {
  text-transform: none !important;
  letter-spacing: initial;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.text--primary {
  font-size: 16px;
}

.text-sm {
  font-size: 12px;
}

::v-deep .dialog-select .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-select .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}
::v-deep .dialog-file-input .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-file-input .v-input__control::before {
  color: red;
  /* Add more CSS styles as needed */
}

::v-deep .dialog-textfield input,
::v-deep .dialog-textfield .v-label {
  font-size: 13px;
  /* Add more CSS styles as needed */
}

::v-deep .script-textarea {
  font-size: 15px;
  font-weight: 600;
  color: red !important;
}
</style>
