<template>
  <div style="width: 100%; height: 100%; background-color: #f2f2f2">
    <!-- App Dashboard top bar-->
    <app-bar class="app-appbar fill-height"></app-bar>

    <!-- App dashboardc left bar -->
    <nav-drawer></nav-drawer>

    <!-- main app container -->
    <v-container fluid class="py-0 app-container">
      <!-- Main app router for dashboard -->
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import AppBar from "../../components/AppBar";
import NavDrawer from "../../components/NavDrawer";

export default {
  components: {
    AppBar,
    NavDrawer,
  },
  data() {
    return {
      dashboard_mapping: { std: "BStd", pre: "BPre", f50: "BF50" },
    };
  },
  computed: {},
  async mounted() {
    await this.$store.dispatch("fetchUser");
    let token = localStorage.getItem("token");
    console.log(this.$store.getters.getUser);
    // navigation on login & dashboard visit
    if (token) {
      // admin redirect
      if (this.$route.name != "CopyWriting" && ["admin"].includes(this.$store.getters.getUser.group)) return this.$router.push({ name: "CopyWriting" });
      // users redirect
      else if (this.$route.name != "CopyWriting" && ["realestate_agent"].includes(this.$store.getters.getUser.type)) this.$router.push({ name: "CopyWriting" });
      else if (this.$route.name != "BlogWriting" && ["blogger"].includes(this.$store.getters.getUser.type)) this.$router.push({ name: "BlogWriting" });
      else if (this.$route.name != "Tender" && ["tender_agent"].includes(this.$store.getters.getUser.type)) this.$router.push({ name: "Tender" });
      return;
    }
    // non-login redirect
    else return this.$router.push({ name: "Login" });
  },
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap"); */
@import "https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/css/rawline.css";
* {
  font-family: "rawline", sans-serif;
}
.app-container {
  background-color: #f2f2f2;
}
.app-appbar {
  background-color: #4049bf !important;
  color: white;
}
.main-snackbar-content {
  padding-bottom: 0px !important;
}
/* ::v-deep .v-label {
  font-weight: 500;
  font-size: 13px !important;
} */
</style>
